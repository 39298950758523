<template>
    <div>
        <v-card>
            <v-card-title>
                Admin
            </v-card-title>
            <v-list dense>
                <v-list-item-group color="primary">
                    <v-list-item 
                        v-for="route in routes" 
                        :key="route.path" 
                        @click="goTo(route.path)">
                        <v-list-item-content>
                            <v-list-item-title v-text="getTitle(route)" />
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </div>
</template>

<script>
import { goTo } from "@/services/routeService";
import tableRoutes from '@/routes/tableRoutes';
import { camelToTitleCase } from "@/services/stringUtility";
import { canList } from "@/features/schemas/services/schemaApi";

export default {
    computed: {
        routes() {
            return tableRoutes.filter(r => canList(r.staticProps.entityKey));
        }
    },

    methods: {
        goTo,
        getTitle(route) {
            return camelToTitleCase(route.name);
        }
    },
};
</script>
